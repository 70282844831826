import { useEffect, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import './style.less';
import { Breadcrumb, Dropdown, Avatar, Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import { setActiveTab, setProgramModal } from '@stores/aiAssistant/aiAssistant';
import { useAdminMenuConstants } from '@atoms/AdminMenu/AdminMenuLists';
import { ROUTE_KEYS, USER_ROLES } from '@stores/constants';
import { ChevronRight } from '@carespace-icons/arrows/chevronRight';
import { ChevronDown } from '@carespace-icons/arrows/chevronDown';
import AToggleMenu from '@atoms/AToggleMenu';
import { useTranslation } from 'react-i18next';

const { Item } = Breadcrumb;

export default function BreadCrumbTab() {
	const [selectedMenu, setSelectedMenu] = useState('');
	const [selectedSubMenu, setSelectedSubMenu] = useState('');
	const activeTab = useTypedSelector(
		state => state.aiAssistant.aiAssistant.activeTab,
	);
	const navigate = useNavigate();
	const dispatch = useTypedDispatch();
	const { selectedUser } = useTypedSelector(state => state.contacts.main);
	const user  = useTypedSelector(state => state.user);
	const userProfile =
		user?.profile?.role === USER_ROLES.USER ? user : selectedUser;
	const { menuUser, menuMapping } = useAdminMenuConstants();
	const {t} = useTranslation()
	
	useEffect(() => {
		setSelectedSubMenu(activeTab);
		setSelectedMenu(menuMapping[activeTab] || '');
	}, [activeTab]);

	const selectedItem = menuUser.find(item => item.key === selectedMenu);
	const subMenuItems = selectedItem?.options || [];
	const selectedSubItem =
		subMenuItems.find(item => item.key === selectedSubMenu) || subMenuItems[0];

	const mainMenu = (
		<Menu
			selectedKeys={[selectedMenu]}
			className='main-menu-dropdown ai-tab-dropdown'
			onClick={({ key }) => {
				const item = menuUser.find(i => i.key === key);
				if (item?.key === 'userActivity' && activeTab != 'activity') {
					setSelectedMenu('activity');
					setSelectedSubMenu('');
					dispatch(setActiveTab('activity'));
					document.body.click();
				}
			}}>
			{menuUser.map(item =>
				item.options?.length ? (
					<Menu.SubMenu
						key={item.key}
						className='sub-menu-dropdown'
						title={
							<div className="flex items-center justify-between w-[150px]">
								<div className="flex items-center gap-2">
									{item.icon}
									<span style={{ color: item.key === menuMapping[activeTab] ? 'var(--tab-text-color)' : '#000' }}>{item.label}</span>
								</div>
								<ChevronRight color="stroke-gray-400" />
							</div>
						}
						expandIcon={() => null}>
						{item.options.map(subItem => (
							<Menu.Item
								key={subItem.key}
								className='sub-menu-item-dropdown'
								onClick={() => {
									setSelectedMenu(item.key);
									setSelectedSubMenu(subItem.key);
									setTimeout(() => dispatch(setActiveTab(subItem.key)), 0);
									subItem.key === 'generateProgram' && dispatch(setProgramModal(true))
								}}>
								<div className="flex items-center gap-2">
									{subItem.icon}
									<span style={{ color: subItem.key === activeTab ? 'var(--tab-text-color)' : '#000' }}>{subItem.name}</span>
								</div>
							</Menu.Item>
						))}
					</Menu.SubMenu>
				) : (
					<Menu.Item key={item.key}>
						<div className="flex items-center gap-2">
							{item.icon}
							<span style={{ color: item.key === menuMapping[activeTab] ? 'var(--tab-text-color)' : '#000' }}>{item.label}</span>
						</div>
					</Menu.Item>
				),
			)}
		</Menu>
	);

	const subMenu = (
		<Menu
			selectedKeys={[selectedSubMenu]}
			className='ai-tab-dropdown'
			onClick={({ key }) => {
				setSelectedSubMenu(key);
				dispatch(setActiveTab(key));
				key === 'generateProgram' && dispatch(setProgramModal(true))
			}}>
			{subMenuItems.map(item => (
				<Menu.Item key={item.key}>
					<div className="flex items-center gap-2">
						{item.icon}
						<span style={{ color: item.key === activeTab ? 'var(--tab-text-color)' : '#000' }}>{item.name}</span>
					</div>
				</Menu.Item>
			))}
		</Menu>
	);

	return (
		<div className="aitab-menu-container">
			<Breadcrumb>
					{
							<div style={{marginRight:'8px'}}><AToggleMenu /></div>
					}
				{!location.pathname.includes(ROUTE_KEYS.DOWNLOAD_APP) && !location.pathname.includes(ROUTE_KEYS.PROGRAM_START) && !location.pathname.includes(ROUTE_KEYS.ROM_SCAN) && !location.pathname.includes(ROUTE_KEYS.ADD_EXERCISE) && !location.pathname.includes(ROUTE_KEYS.POSTURE_SCAN) && <>
				{selectedItem?.label && (
					<Item onClick={() => navigate('/')} className="cursor-pointer">
						<p>{t('Admin.data.menu.home.home')}</p>
					</Item>
				)}
				{user.profile.role != USER_ROLES.USER && (
					<Item
						onClick={() => {
							setSelectedMenu('userActivity');
							setSelectedSubMenu('activity');
							dispatch(setActiveTab('activity'));
						}}
						className="cursor-pointer">
						<>
							{userProfile?.profile?.imageUrl ? (
								<Avatar
									src={userProfile?.profile?.imageUrl}
									alt="avatar"
									size="small"
								/>
							) : (
								<Avatar
									style={{
										backgroundColor:
											userProfile?.profile?.avatarColor || '#1890FF',
										color: '#FFF',
										fontSize: '14px',
										border: 'none',
										display: 'inline-flex',
										alignItems: 'center',
									}}
									alt="avatar"
									size="small">
									{userProfile?.profile?.firstName
										? userProfile?.profile?.firstName?.charAt(0)?.toUpperCase()
										: 'U'}
								</Avatar>
							)}
						</>
						<span className="ml-2">
							{userProfile?.profile?.firstName} {userProfile?.profile?.lastName}
						</span>
					</Item>
				)}
				<Item>
					<Dropdown overlay={mainMenu} trigger={['click']}>
						<a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
							{selectedItem?.label || 'Home'}{' '}
							<ChevronDown color="stroke-gray-400" />
						</a>
					</Dropdown>
				</Item>
				{selectedSubItem && subMenuItems.length > 0 && (
					<Item>
						<Dropdown overlay={subMenu} trigger={['click']}>
							<a
								className="ant-dropdown-link"
								onClick={e => e.preventDefault()}>
								{selectedSubItem?.name || ''}{' '}
								<ChevronDown color="stroke-gray-400" />
							</a>
						</Dropdown>
					</Item>
				)}
				</>}
			</Breadcrumb>
		</div>
	);
}
