import { useTypedSelector } from '@stores/index';
import { IRomPatientResult } from '@stores/interfaces';
import {
	Card,
	Popover,
	Image,
	Table,
	Tag,
	Divider,
	Typography,
	Progress,
	Row,
	Col,
} from 'antd';
import { useEffect, useState } from 'react';
import { jointsTemplateData } from './jointsTemplateData';
import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;

interface JointsDataProps {
	title?: string;
	key: string;
	joint: string;
	measures: {
		name: string;
		value: number;
		score: number;
		normal: number;
		wfl: number;
	}[];
	position: { top: number; left: number };
}

interface BodySilhouetteWithHotspotsProps {
	getColorForCategory: (score: number) => string;
	getCategoryName: (score: number) => string;
	getCategoryDesc: (score: number) => string;
}

const BodySilhouetteWithHotspots = ({
	getColorForCategory,
	getCategoryName,
	getCategoryDesc,
}: BodySilhouetteWithHotspotsProps) => {
	const { t } = useTranslation()
	const [jointsData, setJointsData] = useState<JointsDataProps[]>([]);
	const { selectedRom } = useTypedSelector(state => state.rom.customRom);

	useEffect(() => {
		if (selectedRom) {
			const data = jointsTemplateData.map(jointTemplate => {
				const matchedMeasures =
					selectedRom?.romPatientResults?.flatMap(item => {
						const title = item?.title?.toLowerCase();
						const isMatchingJoint =
							title?.includes(jointTemplate.key) ||
							title?.includes(jointTemplate.joint.toLowerCase());
						const isSpineMatch =
							title?.includes('lumbar') && jointTemplate?.key === 'spine';
						const isExcludedJoint = [
							'neck',
							'spine',
							'lumbar',
							'left wrist',
							'right wrist',
						].some(point => title?.includes(point));

						if (isMatchingJoint || isSpineMatch) {
							return item.results?.map(result => ({
								name:
									result.romProgramExercise?.strapiOmniRomExercise
										?.movementTitle ||
									result?.strapiOmniRomExercise?.name ||
									result.romProgramExercise?.exerciseLibrary?.title ||
									item?.title,
								value: result?.value!,
								score: result?.score!,
								normal: result?.normal!,
								wfl: result?.wfl!,
								bodySideTitle:
									result.romProgramExercise?.strapiOmniRomExercise
										?.bodySideTitle || t('Patient.data.omnirom.noTitle'),
							}))!;
						}

						return item.results
							?.filter(
								result =>
									!isExcludedJoint &&
									(result?.mobilityMapper === jointTemplate.key ||
										result.romProgramExercise?.strapiOmniRomExercise
											?.bodySideTitle == jointTemplate.joint),
							)
							.map(result => ({
								name:
									result.romProgramExercise?.strapiOmniRomExercise
										?.movementTitle ||
									result?.strapiOmniRomExercise?.name ||
									result.romProgramExercise?.exerciseLibrary?.title ||
									item?.title,
								value: result?.value!,
								score: result?.score!,
								normal: result?.normal!,
								wfl: result?.wfl!,
								bodySideTitle:
									result.romProgramExercise?.strapiOmniRomExercise
										?.bodySideTitle || t('Patient.data.omnirom.noTitle'),
							}))!;
					}) || [];

				return {
					...jointTemplate,
					title: matchedMeasures[0]?.bodySideTitle,
					measures: matchedMeasures,
				};
			});

			setJointsData(data);
		}
	}, [selectedRom]);

	const columns = [
		{
			title: t('Patient.data.omnirom.normal'),
			dataIndex: 'normal',
			key: 'normal',
			width: 80,
		},
		{
			title: t('Patient.data.omnirom.wfl'),
			dataIndex: 'wfl',
			key: 'wfl',
			width: 80,
		},
		{
			title: t('Patient.data.omnirom.value'),
			dataIndex: 'value',
			key: 'value',
			render: (value: number, record: IRomPatientResult) => (
				<span
					style={{
						color: getColorForCategory(record.score || 0),
						fontWeight: 'bold',
					}}>
					{value}
				</span>
			),
			width: 80,
		},
		{
			title: t('Patient.data.omnirom.score'),
			dataIndex: 'score',
			key: 'score',
			render: (score: number) => (
				<Tag
					color={getColorForCategory(score)}
					style={{ width: '100%', textAlign: 'center' }}>
					{Math.ceil(score)}
				</Tag>
			),
			width: 80,
		},
	];

	return (
		<Card
			className="body-hotspots"
			style={{ border: 'none', width: '33.4%', minWidth: 'fit-content' }}>
			<div
				className="body-hotspots"
				style={{ width: 205, display: 'inline-block', position: 'relative' }}>
				<Image
					src="/images/front.svg"
					preview={false}
					height={415}
					alt={t('Patient.data.omnirom.bodySilhouette')}
					style={{ filter: 'grayscale(100%)', opacity: 0.7 }}
				/>

				{jointsData?.length > 0 &&
					jointsData.map((joint, index) => {
						
						const avgScore = Math.round(
							joint.measures.reduce((sum, m) => sum + m.score, 0) /
								joint.measures.length,
						);
						const color = getColorForCategory(avgScore);
						const jointInitials = joint.joint
							.split(' ')
							.map(word => word[0])
							.join('');
						return joint?.measures?.length > 0 ? (
							<Popover
								key={index}
								placement="right"
								content={
									<div
										style={{
											width: 320,
											maxHeight: '500px',
											overflow: 'auto',
											userSelect:'none'
										}}>
										<Row>
											<Col flex="auto">
												<Title level={5} style={{ marginBottom: 16 }}>
													{joint.title}
												</Title>
											</Col>
											<Col>
												<Progress
													style={{ '--my-color': color }}
													className="progress-score"
													strokeColor={color}
													percent={avgScore}
													steps={6}
													format={percent => `${percent}`}
												/>
											</Col>
										</Row>
										{joint.measures.map((measure, idx) => {
											return <div key={idx}>
												<Text
													style={{
														fontWeight: 'bold',
														marginTop: idx > 0 ? 20 : 0,
													}}>
													{measure.name}
												</Text>
												<Table
													dataSource={[measure]}
													columns={columns}
													pagination={false}
													bordered
													size="small"
													style={{ marginBottom: 20 }}
												/>
											</div>
								})}

										<Divider style={{ margin: '16px 0' }} />

										<div
											style={{
												backgroundColor: color,
												padding: '10px 16px',
												borderRadius: '4px 4px 0 0',
												color: 'white',
												textAlign: 'center',
												fontWeight: 'bold',
											}}>
											{getCategoryName(avgScore)}
										</div>
										<div
											style={{ backgroundColor: '#F5F5F5', padding: '10px' }}>
											<Text>{getCategoryDesc(avgScore)}</Text>
										</div>
									</div>
								}
								trigger="hover">
								<div
									style={{
										position: 'absolute',
										top: joint.position.top,
										left: joint.position.left,
										width: 24,
										height: 24,
										borderRadius: '50%',
										backgroundColor: color,
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										color: 'white',
										fontWeight: 'bold',
										fontSize: 10,
										cursor: 'pointer',
										boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
										transform: 'translate(-50%, -50%)',
									}}>
									{jointInitials}
								</div>
							</Popover>
						) : (
							<div
								style={{
									position: 'absolute',
									top: joint.position.top,
									left: joint.position.left,
									width: 24,
									height: 24,
									borderRadius: '50%',
									backgroundColor: 'rgb(207 207 207)',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									color: 'white',
									fontWeight: 'bold',
									fontSize: 10,
									cursor: 'pointer',
									transform: 'translate(-50%, -50%)',
								}}
							/>
						);
					})}
			</div>
		</Card>
	);
};

export default BodySilhouetteWithHotspots;
