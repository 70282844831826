import RadarChart from './RadarChart';
import ContentRomResult from './ContentRomTableResult';
import ContentRomResultSides from './ContentROMTableResultSides';
import { Flex, Row, Col, Typography, Button } from 'antd';
import './style.css';
import { useNavigate } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { useEffect } from 'react';
import { router } from '@routers/routers';
import {
	setActiveTab,
	setProgramStateId,
	setStateId,
} from '@stores/aiAssistant/aiAssistant';
import { getUserById } from '@stores/contacts/contacts';
import { ArrowLeft } from '@carespace-icons/arrows/arrowLeft';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const { Title, Text } = Typography;

const AiAssistantCustomRomScanResult = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useTypedDispatch();
	const { selectedRom } = useTypedSelector(state => state.rom.customRom);
	const user = useTypedSelector(state => state.user);
	const { selectedUser } = useTypedSelector(state => state.contacts.main);
	const userId = user.isPhysioterapist ? selectedUser?.id : user?.id;

	useEffect(() => {
		if (!selectedRom || userId != selectedRom?.userId) {
			handleEmptySelectedRom();
		}
	}, [selectedRom, user, selectedUser]);

	const handleEmptySelectedRom = async () => {
		navigate(`/${userId}${router.AIASSISTANT_ROM_SUMMARY}`);
		dispatch(setActiveTab('romSummary'));
		await dispatch(getUserById(userId));
	};

	const handleArrowClick = async () => {
		navigate(`/${selectedRom?.userId}${router.AIASSISTANT_ROM_SUMMARY}`, {
			state: {
				sessionId: selectedRom?.romProgramId,
			},
		});
		dispatch(setActiveTab('romSummary'));
		dispatch(setStateId(selectedRom?.id));
	};

	return (
		<Flex vertical style={{ padding: '5px 25px 25px' }}>
			<Row>
				<Col flex="auto">
					<Title
						type="secondary"
						level={4}
						style={{ display: 'flex', alignItems: 'end', gap: 10 }}>
						<span onClick={handleArrowClick} style={{ cursor: 'pointer' }}>
							<ArrowLeft color="stroke-black/45 hover:stroke-black/70" />
						</span>
						{t('Patient.data.omnirom.romMobilityReport')}
					</Title>
				</Col>
				<Col flex="180px">
					<Text type="secondary">{moment(selectedRom?.createdAt).local().format('LLL')}</Text>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={11}>
					<RadarChart />
				</Col>
				<Col span={13}>
					<ContentRomResultSides />
				</Col>
			</Row>
			<ContentRomResult />
		</Flex>
	);
};
export default AiAssistantCustomRomScanResult;
