export const router = {
	ROOT: '/',
	TEST: '/TEST',
	LOGINCALLBACK: '/login/callback',
	NEW_PATIENTS: '/new-patients',
	NEWPATIENT: '/newpatient',
	METRICS: '/metrics',
	REVIEWED: '/reviewed',
	PENDINGREVIEW: '/pending-review',
	FOLLOWUPREQUIRED: '/follow-up-required',
	OUTOFPARAMETERS: '/out-of-parameters',
	ESCALATIONREQUIRED: '/escalation-required',
	UNASSIGNEDPATIENTS: '/unassigned-patients',
	PENDINGINVITES: '/pending-invites',
	REGISTEREDPATIENTS: '/registered-patients',
	ACTIVITY: '/activity',
	SELECTACTIVITYUSER: '/select-activity-user',
	SELECTUSER: '/select-user',
	OMNIROM: '/omni-rom',
	OMNIROMRESULTS: '/omni-rom/results',
	MOVE: '/move',
	PROGRAMADDEXERCISES: '/program/add-new-exercises',
	OMNIROMADDEXERCISES: '/omni-rom/add-new-exercises',
	USERACTIVITY: '/:userId/activity',
	USERREPORTSUMMARY: '/:userId/report/summary',
	USERVIRTUALEVALUATION: '/:userId/virtual-evaluation/start',
	USERVIRTUALEVALUATIONRESULT: '/:userId/virtual-evaluation/result',
	USERPROFILE: '/profile/:userId',
	USERROMSUMMARY: '/:userId/rom/summary',
	USERROMSCANRESULT: '/:userId/rom/scan-result',
	USERPROGRAMSTART: '/:userId/program/start',
	USERPAGENOTFOUND: '/:userId/page-not-found',
	USERSEARCHNOTFOUND: '/:userId/search-not-found',
	USERROMSCAN: '/:userId/rom/scan',
	USERROMSTARTSCAN: '/:userId/rom/start-scan',
	USERROMCAPTURES: '/:userId/rom/captures',
	POSTURE_ANALYTICS_SCAN: '/:userId/rom/posture-analytics/scan',
	POSTURE_ANALYTICS_SUMMARY: '/:userId/rom/posture-analytics/summary',
	POSTURE_ANALYTICS_CAPTURES: '/:userId/rom/posture-analytics/captures',
	PROGRAM_GENERATE: '/:userId/program/generate',
	PROGRAM_CREATE: '/:userId/program/create',
	PROGRAM_SUMMARY: '/:userId/program/summary',
	SURVEY_ASSIGN: '/:userId/survey/assign',
	SURVEY_CREATE: '/:userId/survey/create',
	SURVEY_START: '/:userId/survey/start',
	SURVEY_SUMMARY: '/:userId/survey/summary',
	REPORT_CREATE: '/:userId/report/create',
	AIASSISTANT_MY_REPORT: '/report/summary',
	AIASSISTANT_LIST_EVALUATION: '/virtual-evaluation/result',
	AIASSISTANT_ROM_SUMMARY: '/rom/summary',
	AIASSISTANT_CAPTURES: '/rom/captures',
	AIASSISTANT_START_SCAN: '/rom/scan',
	AIASSISTANT_START_ROM_SCAN: '/rom/start-scan',
	AIASSISTANT_POSTURE_SCAN: '/rom/posture-analytics/scan',
	AIASSISTANT_POSTURE_SUMMARY: '/rom/posture-analytics/summary',
	AIASSISTANT_POSTURE_CAPTURES: '/rom/posture-analytics/captures',
	AIASSISTANT_CUSTOM_SUMMARY: '/custom/summary',
	AIASSISTANT_CUSTOM_CAPTURES: '/custom/captures',
	AIASSISTANT_GENERATE_PROGRAM: '/program/generate',
	AIASSISTANT_PROGRAMS: '/program/create',
	AIASSISTANT_LIST_SESSIONS: '/program/summary',
	AIASSISTANT_SURVEY_SUMMARY: '/survey/summary',
	AIASSISTANT_VIRTUAL_EVALUATION: '/virtual-evaluation/start',
	AIASSISTANT_ROM_SCAN_RESULT: '/rom/scan-result',
	AIASSISTANT_CREATE_REPORT: '/report/create',
	AIASSISTANT_ASSIGN_SURVEY: '/survey/assign',
	AIASSISTANT_CREATE_SURVEY: '/survey/create',
	AIASSISTANT_START_SURVEY_USER: '/survey/start',
	AIASSISTANT_PAGENOTFOUND: '/page-not-found',
	AIASSISTANT_SEARCHNOTFOUND: '/search-not-found',
	AIASSISTANT_PROGRAM_START: '/program/start',
	DOWNLOAD_APP: '/download-app',
	USER_DOWNLOAD_APP: ':userId/download-app',
	OVERVIEW:':userId/overview'
};
