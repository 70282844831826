import { useEffect, useState } from 'react';
import './style.css';
import { TStrapiBodyPoints } from '@molecules/MBodyPoints/interface';
import { Button, Col, Form, Input, message, Row } from 'antd';
import { ArrowRight } from '@carespace-icons/arrows/arrowRight';
import { getBodyPointStrapi } from '@stores/myLibrary/myLibrary';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { BODY_POINTS } from '@molecules/MBodyPoints/bodyPoint_constants';
import { XClose } from '@carespace-icons/general/xClose';
import SelectCustom from '@atoms/ASelect';
import { BodyPointOptionsRightMockData, NECK_SPINE } from './BodyPointOptionsRightMockData';
import { nextSequence, updateOmniRomRecordConsult } from '@stores/rehab/main';
import { saveOmniRomPhysioterapistVideo } from '@stores/rom/romTemplates';
import { fetchExercises } from '@stores/rom/results';
import { ExercisePoints, RehabVideoState, SimplifiedPoints } from '@stores/interfaces';
import { useTranslation } from 'react-i18next';
import { Trash01 } from '@carespace-icons/general/trash01';

interface IRomImageModal {
  setVideoState: (value: RehabVideoState | undefined) => void
}

export default function BodypointsSelectionStep(props: IRomImageModal) {
  const { setVideoState } = props
  const { t } = useTranslation()
  const [activeRightName, setActiveRightName] = useState('');
  const [bodyPointData, setBodyPointData] = useState<TStrapiBodyPoints[]>([]);
  const [selectedBodyPoint, setSelectedBodyPoint] = useState<number | null>(null);
  const [savedBodyPoints, setSavedBodyPoints] = useState<any[]>([]);
  const [exerciseData, setExerciseData] = useState<any[]>([]);
  const [formDataRight, setFormDataRight] = useState({ normal: 0, wfl: 0, min: 0, max: 0, kinematics: '', pointsToCalculateAngle: {}, pointsToValidatePosition: {}, function: '' });
  const [form] = Form.useForm();
  const [isSaveClick, setIsSaveClick] = useState(false);
  const [errorObject, setErrorObject] = useState<string[]>([]);
  const [combinedName, setCombinedName] = useState('');
  const [kinematicsValue, setKinematicsValue] = useState('');

  const dispatch = useTypedDispatch();
  const { isOmniRomRecord, sequence } = useTypedSelector((state) => state.rehab.main);
  const { romUploadDetails } = useTypedSelector((state) => state.rom.romTemplates)
  const user = useTypedSelector((state) => state.user);

  useEffect(() => {
    fetchData();
  }, []);

  const simplifyPoints = (points: ExercisePoints): SimplifiedPoints => {
    return {
      a: {
        value: points?.a?.value,
        name: points?.a?.name,
      },
      b: {
        value: points?.b?.value,
        name: points?.b?.name,
      },
      c: {
        value: points?.c?.value,
        name: points?.c?.name,
      },
    };
  };

  useEffect(() => {
    if (selectedBodyPoint !== null && activeRightName && combinedName) {

      if (combinedName.includes("Spine")) {
        setCombinedName(combinedName.replace("Spine", "Lumbar"));
      }
      
      const selectedExercise = exerciseData.find(
        (exercise) => exercise?.name?.trim().toLowerCase() === combinedName?.trim().toLowerCase()
      );

      if (selectedExercise) {
        const value = selectedExercise.reference?.name?.includes(kinematicsValue);
        setFormDataRight(() => ({
          strapiOmniRomExerciseId: selectedExercise?.id,
          function: selectedExercise?.function,
          pointsToCalculateAngle: simplifyPoints(selectedExercise?.pointsToCalculateAngle),
          pointsToValidatePosition: simplifyPoints(selectedExercise?.pointsToValidatePosition),
          normal: selectedExercise.reference?.normal,
          wfl: selectedExercise.reference?.wfl,
          min: selectedExercise.reference?.min,
          max: selectedExercise.reference?.normal,
          kinematics: value,
        }));
      }
    }
  }, [activeRightName, selectedBodyPoint, exerciseData, combinedName, kinematicsValue]);

  useEffect(() => {
    setFormDataRight({
      pointsToCalculateAngle: '', pointsToValidatePosition: '', function: '',
      normal: 0,
      wfl: 0,
      min: 0,
      max: 0,
      kinematics: ''
    });
    setCombinedName('');
    setKinematicsValue('');
    form.resetFields();
  }, [activeRightName]);

  useEffect(() => {
    form.setFieldsValue(formDataRight);
  }, [formDataRight, form]);

  const fetchData = async () => {
    const data = await dispatch(getBodyPointStrapi());
    const strapiData = data.payload;
    const temp_data: TStrapiBodyPoints[] = strapiData.map((item: TStrapiBodyPoints) => ({
      ...item,
      romStyle: BODY_POINTS.find((point: TStrapiBodyPoints) => point.id === item.id)?.romStyle
    }));    
    setBodyPointData(temp_data);
    const exercises = await dispatch(fetchExercises());
    setExerciseData(exercises.payload);
  };

  const onBodyPoint = (bodyPoint: TStrapiBodyPoints) => {
    setSelectedBodyPoint(bodyPoint.id);
    setActiveRightName(bodyPoint.attributes.name);
  };

  const closeRight = () => {
    setActiveRightName('');
  };

  const onSelectBack = (name: string, value: string | string[]) => {
    setErrorObject((prevErrors) => prevErrors.filter((item: string) => item !== name));
    setKinematicsValue(value);
    setFormDataRight((prevFormData) => ({ ...prevFormData, kinematics: value }));

    const combinedExerciseName = `${activeRightName} ${Array.isArray(value) ? value.join(' ') : value}`;
    setCombinedName(combinedExerciseName);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormDataRight((prevFormData) => ({
      ...prevFormData,
      [name]: parseInt(value) || 0,
    }));
  };

  const handleSaveButton = () => {
    setIsSaveClick(true);

    const newBodyPoint = { ...formDataRight, name: activeRightName, kinematics: kinematicsValue };
    setSavedBodyPoints((prevBodyPoints) => {
      const existingIndex = prevBodyPoints.findIndex((point) => point.name === activeRightName);
      if (existingIndex !== -1) {
        const updatedPoints = [...prevBodyPoints];
        updatedPoints[existingIndex] = { ...prevBodyPoints[existingIndex], ...newBodyPoint };
        return updatedPoints;
      }
      return [...prevBodyPoints, newBodyPoint];
    });

    setBodyPointData((prevData) =>
      prevData.map((point) =>
        point.attributes.name === activeRightName ? { ...point, isSaved: true } : point
      )
    );

    message.success(t('Patient.data.omnirom.savedMsg'));
    closeRight();
  };

  const handleNextButton = async () => {
    if (savedBodyPoints.length === 0) {
      message.error(t('Patient.data.omnirom.bodyPointErr'));
      return;
    }
    if (isOmniRomRecord) {
      dispatch(nextSequence(sequence?.next));
      dispatch(updateOmniRomRecordConsult(savedBodyPoints));
      setVideoState(RehabVideoState.START)
    } else {
      dispatch(nextSequence({ value: 'resultRecordScreen', next: null }));

      const form = new FormData();
      form.append('physioterapistId', user.id);
      let videos = romUploadDetails.videos ? [...romUploadDetails.videos] : [];
      videos.forEach(video => {
        form.append('video', video);
      });

      savedBodyPoints?.forEach((exercise, index: number) => {
        form.append(`exercises[create][${index}][strapiOmniRomExerciseId]`, exercise?.strapiOmniRomExerciseId || 0)
        form.append(`exercises[create][${index}][normal]`, exercise?.normal);
        form.append(`exercises[create][${index}][wfl]`, exercise?.wfl);
        form.append(`exercises[create][${index}][min]`, exercise?.min);
        form.append(`exercises[create][${index}][max]`, exercise?.max);
      });

      for (const [key, value] of Object.entries(romUploadDetails)) {
        if (key !== 'videos') {
          form.append(key, value || '');
        }
      }
      try {
        await dispatch(saveOmniRomPhysioterapistVideo(form));
        message.success(t("Patient.data.omnirom.saveSuccess"))
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    }
  };

  const deleteBodyPoint = () => {
    setSavedBodyPoints((prevPoints) =>
      prevPoints.filter((point) => point.name !== activeRightName)
    );
    setBodyPointData((prevData) =>
      prevData.map((point) =>
        point.attributes.name === activeRightName ? { ...point, isSaved: false } : point
      )
    );
    message.success('Body Point Deleted Successfully !!');
    closeRight();
  };

  return (
    <>
      <span className="header-bodypoints">{t('Patient.data.omnirom.selectBodyParts')}</span>
      <div className="w-full h-full flex justify-center">
        <div className="flex overflow-x-auto w-full h-[605px] justify-center">
          {savedBodyPoints.length > 0 && (
            <div className="w-2/5 pl-6 pr-6 mt-4" style={{ minWidth: '280px' }}>
              <div className="w-full rounded-lg p-5 border-2 border-[#d1d5db] hover:shadow-lg bg-gray-100">
                <h2 className="text-primary-700 font-bold text-lg text-left mb-4">
                  📋 {t('Patient.data.omnirom.savedBodyPoints')}
                </h2>
                <ul className="list-disc list-inside text-gray-800">
                  {savedBodyPoints.map((item, index) => (
                    <li key={index} className="text-base py-1">
                      <span className="font-semibold text-primary-600">{item.name}</span> - {item.kinematics}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          <div className="bg-gray-50 select-none p-5 rounded-md mb-6 mt-2">
            <div className="body-shape-front-css">
              <img src="/images/front.svg" width={240} alt="Body front" />
              {bodyPointData.filter((val) => val.attributes.position === 'front').map((hotspot, index) => (
                <>{
                  <div
                    key={index}
                    style={hotspot.romStyle}
                    className={
                      hotspot.attributes.name === activeRightName
                        ? 'hotspot-circle-selection active'
                        : hotspot.isSaved
                          ? 'hotspot-circle-selection saved'
                          : 'hotspot-circle-selection'
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      onBodyPoint(hotspot);
                    }}
                  >
                    <span className="tooltiptext">{hotspot.attributes.name}</span>
                  </div>}
                </>
              ))}
            </div>
            <Button onClick={handleNextButton} type="primary" block style={{ marginBottom: '10px' }}>
              <span className="whitespace-nowrap" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}>
                {isOmniRomRecord ? t('Patient.data.omnirom.next') : t('Patient.data.omnirom.save')}
                <ArrowRight width={20} height={20} />
              </span>
            </Button>
          </div>
          {activeRightName && (
            <div className="w-1/3 pl-6 pr-6 mt-2" style={{ minWidth: '280px' }}>
              <div className="w-full rounded-lg p-3 border-2 border-[#eaecf0] hover:shadow-2xl bg-white">
                <div className="relative mb-10">
                  {savedBodyPoints.some(point => point.name === activeRightName) && (
                    <span className="absolute right-9 cursor-pointer" onClick={deleteBodyPoint}>
                      <Trash01 color="stroke-gray-600" width={20} height={20} />
                    </span>
                  )}
                  <span className="absolute right-1 cursor-pointer" onClick={closeRight}>
                    <XClose color="stroke-gray-600" />
                  </span>
                  <h2 className="text-primary-600 font-semibold text-base absolute text-left">
                    {activeRightName.toUpperCase()}
                  </h2>
                </div>
                <div className="mt-2">
                  <Form form={form} layout="vertical" onFinish={handleSaveButton} initialValues={formDataRight}>
                    <Form.Item
                      name="kinematics"
                      label={activeRightName.toUpperCase()}
                    >
                      <SelectCustom
                        onChange={onSelectBack}
                        options={BodyPointOptionsRightMockData.find(item => item.name === activeRightName)?.options}
                        name={BodyPointOptionsRightMockData.find(item => item.name === activeRightName)?.key || ''}
                        defaultValue={kinematicsValue}
                        isError={errorObject.includes(BodyPointOptionsRightMockData?.find(item => item?.name === activeRightName)?.key) && isSaveClick}
                      />
                    </Form.Item>
                    <Row gutter={24} className="mt-2">
                      <Col span={12}>
                        <Form.Item
                          label="Normal"
                          name="normal"
                        >
                          <Input
                            type="number"
                            name="normal"
                            defaultValue={formDataRight.normal}
                            onChange={handleInputChange}
                            placeholder="Enter Normal"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="WFL"
                          name="wfl"
                        >
                          <Input
                            type="number"
                            name="wfl"
                            defaultValue={formDataRight.wfl}
                            onChange={handleInputChange}
                            placeholder="Enter WFL"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Min"
                          name="min"
                        >
                          <Input
                            type="number"
                            name="min"
                            defaultValue={formDataRight.min}
                            onChange={handleInputChange}
                            placeholder="Enter Min"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Max"
                          name="max"
                        >
                          <Input
                            type="number"
                            name="max"
                            defaultValue={formDataRight.max}
                            onChange={handleInputChange}
                            placeholder="Enter Max"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Button type="primary" htmlType="submit" className="w-full">
                      {t('Patient.data.omnirom.save')}
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
