import { Col, Drawer, Row, Tooltip, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { MdInfoOutline, MdMenu } from 'react-icons/md';
import ExerciseListMenu from './ExerciseListMenu';
import Tutorial from './Tutorial';
import Main from './Main';
import SplashPage from './SplashPage';
import VideoPoseEstimator from '@organisms/OVideoPoseEstimatorUpdated';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { toggleLoading } from '@stores/rom/main';
import { IRowDataProps } from '@stores/interfaces';
import RomResultData from './RomResultData';
import ExerciseInfo from './ExerciseInfo';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useTheme } from '@pages/Themes/ThemeContext';
import { THEME } from '@stores/constants';

export default function RomData(props: IRowDataProps) {
	const {
		isSplashOpened,
		isDashboard,
		onToggleMenu,
		isVideoPause,
		onTogglePauseVideo,
		isFullscreen,
		onToggleTutorial,
		isSwitchMode,
		isMenuOpened,
		isTutorialOpened,
		onTogglesSplashPage,
		onExerciseValueAndCoordinates,
		onBodyPointsVisible,
		onNextTransition,
		fullscreenRef,
		isCompleted,
		setCompleted,
		setIsManual,
		isManual,
		onToggleInfo,
		isInfoOpened,
		onSavePhysicalAssessmentsMetrics,
	} = props;
	const isCollapsible = useTypedSelector(
			state => state.aiAssistant.aiAssistant.isCollapsible,
		);
	const { t } = useTranslation();
	const dispatch = useTypedDispatch();
	const {
		exercises,
		currentExercise,
		transition,
		loading,
		selfieMode,
		finishedExercises,
		cameraId,
	} = useTypedSelector(state => state.rom.main);

	const exerciseNumber = exercises?.findIndex(
		exer => exer.id === currentExercise?.id,
	);

	const onLoading = () => dispatch(toggleLoading(false));
	const [transitionTime, setTransitionTime] = useState<number>();
	const {theme} = useTheme()
	return (
		<div
			ref={fullscreenRef}
			style={{
				...( !isFullscreen && ((isDashboard || !isCollapsible) ? { padding: '20px 40px 40px 40px' } : { padding: '0px 40px 40px 40px' })),
				width: "90vw"
			}}>			
			<div className="flex items-center justify-center h-[35px]" style={{backgroundColor:theme===THEME.VIBRANT?'var(--primary-900)':'#000'}}>
				<div className="flex-1">
					<div className="flex items-center pl-2">
						<div className="h-[35px] gap-2 mr-2 flex justify-center items-center mr-1">
							{!isSplashOpened &&
								transitionTime &&
								!finishedExercises?.finished &&
								!isCompleted && (
									<>
										<Tooltip title={t('Patient.data.omnirom.menu')}>
											<MdMenu
												size={30}
												className="cursor-pointer text-gray-500"
												onClick={onToggleMenu}
											/>
										</Tooltip>
										<Tooltip title="Info">
											<MdInfoOutline
												size={26}
												className="cursor-pointer text-gray-500"
												onClick={onToggleInfo}
											/>
										</Tooltip>
									</>
								)}
						</div>
						{!finishedExercises?.finished && !isCompleted && transitionTime && (
							<div className="h-[35px] flex-1 flex items-center">
								<p className="text-white">
									{currentExercise &&
										`${exerciseNumber! + 1}/${exercises?.length} ${(currentExercise?.name ? currentExercise?.name : currentExercise?.title)?.toUpperCase()}`}
								</p>
							</div>
						)}
					</div>
				</div>
				<RomResultData {...props} transitionTime={transitionTime} />
			</div>

			<div
				className='relative z-10 overflow-hidden' style={{
					...( !isFullscreen ? {aspectRatio:'4/2'} : {height:'97vh'})}}>
				<Drawer
					placement="left"
					closable={false}
					onClose={onToggleMenu}
					open={isMenuOpened}
					getContainer={false}
					forceRender={isFullscreen}
					className={`${isMenuOpened ? 'h-full' : 'h-0'}`}
					bodyStyle={{
						padding: 0,
						backgroundColor: '#642cb9e3',
						...( !isFullscreen && {aspectRatio:'4/2'})
					}}>
					<ExerciseListMenu onToggleMenu={onToggleMenu} />
				</Drawer>

				<Drawer
					placement="top"
					closable={false}
					className="w-full"
					styles={{wrapper: {
						height: 'auto'
					}}}
					onClose={onToggleInfo}
					open={isInfoOpened}
					getContainer={false}
					forceRender={isFullscreen}
					bodyStyle={{
						backgroundColor: 'rgba(0, 0, 0, 0.65)',
						backdropFilter: 'blur(8px)',
					}}>
					<ExerciseInfo />
				</Drawer>

				{isTutorialOpened && <Tutorial onToggleTutorial={onToggleTutorial} />}
				{isSplashOpened ? (
					<SplashPage onTogglesSplashPage={onTogglesSplashPage} />
				) : (
					<Main
						isVideoPause={isVideoPause}
						onTogglePauseVideo={onTogglePauseVideo}
						isSwitchMode={isSwitchMode}
						isFullscreen={isFullscreen}
						setIsManual={setIsManual}
						isManual={isManual}
						isCompleted={isCompleted}
						setCompleted={setCompleted}
						onSavePhysicalAssessmentsMetrics={onSavePhysicalAssessmentsMetrics}
						transitionTime={transitionTime}
						isDashboard={isDashboard}
						setTransitionTime={setTransitionTime}>
						<VideoPoseEstimator
							loading={loading}
							currentExercise={currentExercise}
							isFullscreen={isFullscreen}
							onLoading={onLoading}
							isCompleted={isCompleted}
							onExerciseValueAndCoordinates={onExerciseValueAndCoordinates}
							onBodyPointsVisible={onBodyPointsVisible}
							selfieMode={selfieMode}
							cameraId={cameraId}
							transition={transition}
							isSwitchMode={isSwitchMode}
							onNextTransition={onNextTransition}
						/>
					</Main>
				)}
				<img
					className="absolute z-20 bottom-10 left-10 select-none w-[60px] md:w-[80px]"
					src="/images/OmniRome.svg"
					alt="omnirom"
				/>
			</div>
		</div>
	);
}
