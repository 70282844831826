import { Logomark } from '@carespace-icons/Logo/logomark';
import { useTranslation } from 'react-i18next';
import Settings from '@pages/Settings';
import { Avatar, Dropdown, Menu, Popover, Space } from 'antd';
import {
	APIKeyData,
	IAdminSideBarProps,
	IMenuAdminProps,
} from '@stores/interfaces';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { useEffect, useState } from 'react';
import { getSettingApiKey } from '@stores/settings/setings';
import { useBlockNavigation } from '@atoms/ABlockNavigation';
import { router } from '@routers/routers';
import { useNavigate } from 'react-router-dom';
import { ACTIVETAB, ADMIN_KEYS, ADMIN_MENU, ADMIN_MENU_CLICK, navigationConfig, ROUTE_KEYS, USER_ROLES } from '@stores/constants';
import ThemeSelector from '@pages/Themes/ThemeSelector';
import { useAdminMenuConstants } from './AdminMenuLists';
import { FaCaretLeft } from 'react-icons/fa';
import { setProgramModal } from '@stores/aiAssistant/aiAssistant';

export default function AdminMenuSideBar(props: IAdminSideBarProps) {
	const {
		onChangeHome,
		selectedItem,
		activeMenu,
		setActiveMenu,
		setSearchQuery,
		setSelectedItem,
	} = props;
	const { t } = useTranslation();
	const activeTab = useTypedSelector(
		state => state.aiAssistant.aiAssistant.activeTab,
	);
	const { menuMapping } = useAdminMenuConstants();
	const currentLocation = window.location.pathname;
	const { handleNavigation } = useBlockNavigation(
		location.pathname.includes(ROUTE_KEYS.PROGRAM_START),
		t('Admin.data.menu.aiAssistant.aiAssistantListSessions.unsavedChanges'),
	);
	const user = useTypedSelector(state => state.user);
	const { selectedUser } = useTypedSelector(state => state.contacts.main);
	const [isModalSettingsOpened, setIsModalSettingsOpened] = useState(false);
	const [settingData, setSettingData] = useState<APIKeyData | undefined>(
		undefined,
	);
	const [inviteCode, setInviteCode] = useState('');
	const [apiKey, setApiKey] = useState('');
	const dispatch = useTypedDispatch();
	const isSuperAdmin = user?.profile?.role === USER_ROLES.SUPER_ADMIN;
	const navigate = useNavigate();
	const { menuUser, adminProfileData, menuAdminList, menuSuperAdminList } =
		useAdminMenuConstants();
	const [profileMenuOpen, setProfileMenuOpen] = useState(false);
	const [optionMenu, setOptionMenu] = useState('');
	useEffect(() => {
		if (isSuperAdmin) {
			setSelectedItem(null);
			setActiveMenu(ADMIN_KEYS.CONTACTS);
		} else if (
			activeMenu === ADMIN_MENU.DEFAULT ||
			activeMenu === ADMIN_KEYS.ACTIVITY ||
			activeMenu === ACTIVETAB.DOWNLOAD_APP ||
			activeMenu === router.OUTOFPARAMETERS ||
			activeMenu === router.PENDINGREVIEW ||
			activeMenu === router.FOLLOWUPREQUIRED ||
			activeMenu ===router.NEWPATIENT ||
			activeMenu === router.REGISTEREDPATIENTS ||
			activeMenu === router.ESCALATIONREQUIRED
		) {
			setSelectedItem(null);
			setOptionMenu('');
		}
	}, [activeMenu]);

	useEffect(() => {
		const resetStates = [
			router.OUTOFPARAMETERS,
			router.PENDINGREVIEW,
			router.FOLLOWUPREQUIRED,
			router.NEWPATIENT,
			router.REGISTEREDPATIENTS,
			router.ESCALATIONREQUIRED
		];

		if (resetStates.includes(activeMenu)) {
			setSelectedItem(null);
			setOptionMenu('');
		}
	}, [currentLocation]);

	const [menuAdmin, setMenuAdmin] = useState(
		user?.profile?.role === USER_ROLES.ADMIN &&
			user?.profile?.role != USER_ROLES.USER
			? menuAdminList
			: menuSuperAdminList,
	);

	useEffect(() => {
		if (selectedItem) {
			user?.profile?.role === USER_ROLES.ADMIN
				? setMenuAdmin(menuAdminList)
				: setMenuAdmin(menuSuperAdminList);
		} else {
			user?.profile?.role === USER_ROLES.ADMIN
				? setMenuAdmin(menuAdminList.slice(0, -1))
				: setMenuAdmin(menuSuperAdminList.slice(0, -1));
		}
		if (selectedItem && activeMenu === ADMIN_KEYS.CONTACTS) {
			setOptionMenu(ADMIN_KEYS.TOOLS);
		} else if (!selectedItem) {
			setOptionMenu(ADMIN_MENU.DEFAULT);
		}
	}, [selectedItem]);

	const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

	const filteredMenuAdmin = menuAdmin;

	const onChangeAdminProfie = (value: string) => {
		if (value === ADMIN_MENU_CLICK.SETTINGS) {
			fetchSettingApiKey();
			setIsModalSettingsOpened(true);
		} else {
			props.onClick(value);
		}
	};

	const fetchSettingApiKey = async () => {
		const data = await dispatch(getSettingApiKey());
		setApiKey(data.payload?.openaiApiKey);
		setSettingData(data.payload);
		setInviteCode(data.payload?.client?.inviteCode);
	};

	const popoverContent = (item: string) => {
		return <span className="popup-label-capitalize">{item}</span>;
	};

	const [toolsMenuOpen, setToolsMenuOpen] = useState(false);

	const getMenu = (options: any[], parentKey: string) => (
		<div className="flex items-center justify-center custom-dropdown-menu-sidebar">
				<div style={{
					right: "95.9%",
					position: "absolute"
				}}>
				{options?.length > 0 && <FaCaretLeft color="white" size={20} />}
			</div>
			<Menu
				onMouseLeave={() => {
					setDropdownVisible(false);
					setToolsMenuOpen(false);
				}}
				className="custom-space-dropdown">
				{options?.map((option, idx) => (
					<Menu.Item
						key={idx}
						title=""
						onClick={() => {
							if (
								option.key != ADMIN_MENU_CLICK.THEME_SELECTOR &&
								option?.key != ADMIN_MENU_CLICK.SETTINGS &&
								option.key != ADMIN_MENU_CLICK.LOGOUT
							) {
								navigate(
									`/${selectedUser?.id}${navigationConfig[option.key].path}`,
								);
								option?.key === ADMIN_KEYS.GENERATE_PROGRAM &&
									dispatch(setProgramModal(true));
							}
						}}>
						<Space className="flex items-center p-1 hover:bg-slate-100 hover:rounded-lg">
							{option.icon}{' '}
							{option.key != ADMIN_MENU_CLICK.THEME_SELECTOR ? (
								<span
									style={{
										color:
											option.key === activeTab ? 'var(--tab-text-color)' : '#000',
									}}>
									{option.name || option.label}
								</span>
							) : (
								<ThemeSelector />
							)}
						</Space>
					</Menu.Item>
				))}
			</Menu>
		</div>
	);
	return (
		<div className="overflow-auto">
			<ul className="admin-menu">
				<li className="logo" onClick={() => onChangeHome('')}>
					<Logomark />
				</li>
				{filteredMenuAdmin.map((item: IMenuAdminProps, index) => {
					if (item.key == ADMIN_KEYS.CONTACTS) {
						return (
							<Popover
								key={index}
								content={popoverContent(
									t('Admin.data.menu.contacts.patients.patients'),
								)}
								title=""
								placement="right">
								<li
									className={`menu-item ${activeMenu === item.key && optionMenu != ADMIN_KEYS.TOOLS ? 'menu-item-active' : ''}`}
									onClick={() => {
										isSuperAdmin
											? onChangeHome('')
											: handleNavigation(router.SELECTUSER, {}, () => {
													setSearchQuery('');
													setActiveMenu(item.key);
												});
										setSearchQuery('');
										setActiveMenu(item.key);
										setSelectedItem(null)
									}}>
									{item.icon}
								</li>
							</Popover>
						);
					} else if (item.key == ADMIN_KEYS.ACTIVITY) {
						return (
							<Popover
								key={index}
								content={popoverContent(item.key)}
								title=""
								placement="right">
								<li
									className={`menu-item ${activeMenu === item.key ? 'menu-item-active' : ''}`}
									onClick={() => {
										if (location.pathname.includes(ROUTE_KEYS.PROGRAM_START)) {
											handleNavigation(router.SELECTACTIVITYUSER, {}, () => {
												setActiveMenu(item.key);
											});
										} else {
											navigate(router.SELECTACTIVITYUSER);
											setSelectedItem(null);
											setActiveMenu(item.key);
										}
									}}>
									{item.icon}
								</li>
							</Popover>
						);
					} else if (item.key == ACTIVETAB.DOWNLOAD_APP) {
						return (
							<Popover
								key={index}
								content={popoverContent(item.label)}
								title=""
								placement="right">
								<li
									className={`menu-item ${activeMenu === item.key ? 'menu-item-active' : ''}`}
									onClick={() => {
										navigate(`/${user?.id}${router.DOWNLOAD_APP}`);
										setActiveMenu(item.key);
									}}>
									{item.icon}
								</li>
							</Popover>
						);
					} else if (item.key == ADMIN_MENU.DEFAULT) {
						return (
							<Popover
								key={index}
								content={popoverContent(item.label)}
								title=""
								placement="right">
								<li
									className={`menu-item ${activeMenu === item.key ? 'menu-item-active' : ''}`}
									onClick={() => {
										onChangeHome('');
									}}>
									{item.icon}
								</li>
							</Popover>
						);
					} else if (item.key == ADMIN_KEYS.TOOLS) {
						return (
							<Popover
								key={index}
								content={
									<div>
										{menuUser.map(menuItem => (
											<div key={menuItem.key} className="relative">
												<Dropdown
													overlay={getMenu(menuItem.options, item.key)}
													className={`p-2`}
													placement="right"
													trigger={['hover']}
													onOpenChange={visible => {
														setDropdownVisible(visible);
													}}
													overlayStyle={{
														position: 'absolute',
														left: '220px',
													}}>
													<span
														className="cursor-pointer flex items-center gap-2 hover:bg-slate-100 hover:rounded-lg"
														style={{
															color:
																menuItem.key === menuMapping[activeTab]
																	? 'var(--tab-text-color)'
																	: '#000',
														}}
														onClick={() => {
															if (menuItem?.key === ADMIN_KEYS.USERACTIVITY) {
																navigate(
																	`/${selectedUser?.id}${navigationConfig[menuItem.key].path}`,
																);
																setDropdownVisible(false)
																setToolsMenuOpen(false)
															}
														}}>
														{menuItem.icon} {menuItem.label}
													</span>
												</Dropdown>
											</div>
										))}
									</div>
								}
								placement="right"
								open={toolsMenuOpen}
								onOpenChange={visible => {
									if (visible) {
										setToolsMenuOpen(visible);
									} else {
										!dropdownVisible && setToolsMenuOpen(visible);
									}
								}}>
								<li
									className={`menu-item ${optionMenu === item.key ? 'menu-item-active' : ''}`}
									onClick={() => setToolsMenuOpen(prev => !prev)}>
									{item.icon}
								</li>
							</Popover>
						);
					}
				})}
			</ul>
			<ul
				className="admin-profile"
				style={{
					bottom:
						currentLocation.includes(ROUTE_KEYS.DOWNLOAD_APP) &&
						(user?.profile?.role === USER_ROLES.ADMIN ||
							user?.profile?.role === USER_ROLES.SUPER_ADMIN)
							? '10px'
							: '25px',
					left: '40px',
				}}>
				<li>
					<Popover
						content={
							<>
								{adminProfileData.map(menuItem => (
									<li
										key={menuItem.key}
										className="cursor-pointer flex items-center p-2 hover:bg-slate-100 hover:rounded-lg"
										onClick={() => {
											if (
												menuItem.key === ACTIVETAB.DOWNLOAD_APP
											) {
												navigate(
													`/${user?.id}${navigationConfig[menuItem.key].path}`,
												);
												setOptionMenu(ADMIN_KEYS.TOOLS);
											} else if (menuItem.key === ADMIN_MENU_CLICK.LOGOUT) {
												props.onClick(ADMIN_MENU_CLICK.LOGOUT);
											} else if (menuItem?.key === ADMIN_MENU_CLICK.SETTINGS) {
												onChangeAdminProfie(menuItem.key);
											}
											setProfileMenuOpen(false);
										}}>
										{menuItem?.key != ADMIN_MENU_CLICK.THEME_SELECTOR
											? menuItem.label
											: menuItem.icon}
									</li>
								))}
							</>
						}
						placement="right"
						open={profileMenuOpen}
						onOpenChange={setProfileMenuOpen}>
						<span className="cursor-pointer flex items-center">
							<>
								{user?.profile?.imageUrl ? (
									<Avatar
										src={user?.profile?.imageUrl}
										alt="avatar"
										size={30}
									/>
								) : (
									<Avatar
										style={{
											backgroundColor: user?.profile?.avatarColor || '#1890FF',
											color: '#FFF',
											fontSize: '20px',
											border: '2px solid white',
											display: 'inline-flex',
											alignItems: 'center',
										}}
										alt="avatar"
										size={30}>
										{user.profile?.firstName.charAt(0).toUpperCase()}
									</Avatar>
								)}
							</>
						</span>
					</Popover>
				</li>
				<Settings
					isModalOpen={isModalSettingsOpened}
					setIsModalSettingsOpened={setIsModalSettingsOpened}
					settingData={settingData}
					apiKey={apiKey}
					setApiKey={setApiKey}
					inviteCode={inviteCode}
				/>
			</ul>
		</div>
	);
}