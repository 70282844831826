export const BodyPointOptionsRightMockData = [
  {
    name: 'Left Wrist',
    key: 'leftWrist',
    options: [
      { value: 'Flexion', label: 'Flexion' },
      { value: 'Extension', label: 'Extension' },
      { value: 'Abduction', label: 'Abduction' },
      { value: 'External Rotation', label: 'External Rotation' },
      { value: 'Internal Rotation', label: 'Internal Rotation' }
    ],
    selectedOption: 'Extension' | 'Select Option'
  },
  {
    name: 'Right Wrist',
    key: 'rightWrist',
    options: [
      { value: 'Flexion', label: 'Flexion' },
      { value: 'Extension', label: 'Extension' },
      { value: 'Abduction', label: 'Abduction' },
      { value: 'External Rotation', label: 'External Rotation' },
      { value: 'Internal Rotation', label: 'Internal Rotation' }
    ],
    selectedOption: 'Extension' | 'Select Option'
  },
  {
    name: 'Right Ankle',
    key: 'rightAnkle',
    options: [
      { value: 'Flexion', label: 'Flexion' },
      { value: 'Extension', label: 'Extension' },
      { value: 'Abduction', label: 'Abduction' },
      { value: 'External Rotation', label: 'External Rotation' },
      { value: 'Internal Rotation', label: 'Internal Rotation' }
    ],
    selectedOption: 'Extension' | 'Select Option'
  },
  {
    name: 'Left Ankle',
    key: 'leftAnkle',
    options: [
      { value: 'Flexion', label: 'Flexion' },
      { value: 'Extension', label: 'Extension' },
      { value: 'Abduction', label: 'Abduction' },
      { value: 'External Rotation', label: 'External Rotation' },
      { value: 'Internal Rotation', label: 'Internal Rotation' }
    ],
    selectedOption: 'Extension' | 'Select Option'
  },
  {
    name: 'Right Shoulder',
    key: 'rightShoulder',
    options: [
      { value: 'Flexion', label: 'Flexion' },
      { value: 'Extension', label: 'Extension' },
      { value: 'Abduction', label: 'Abduction' },
      { value: 'External Rotation', label: 'External Rotation' },
      { value: 'Internal Rotation', label: 'Internal Rotation' }
    ],
    selectedOption: 'Extension' | 'Select Option'
  },
  {
    name: 'Left Shoulder',
    key: 'leftShoulder',
    options: [
      { value: 'Flexion', label: 'Flexion' },
      { value: 'Extension', label: 'Extension' },
      { value: 'Abduction', label: 'Abduction' },
      { value: 'External Rotation', label: 'External Rotation' },
      { value: 'Internal Rotation', label: 'Internal Rotation' }
    ],
    selectedOption: 'Extension' | 'Select Option'
  },
  {
    name: 'Left Elbow',
    key: 'leftElbow',
    options: [
      { value: 'Flexion', label: 'Flexion' },
      { value: 'Extension', label: 'Extension' }
    ],
    selectedOption: 'Extension' | 'Select Option'
  },
  {
    name: 'Right Elbow',
    key: 'rightElbow',
    options: [
      { value: 'Flexion', label: 'Flexion' },
      { value: 'Extension', label: 'Extension' }
    ],
    selectedOption: 'Extension' | 'Select Option'
  },{
    name: 'Left Hip',
    key: 'leftHip',
    options: [
      { value: 'Supine Flexion', label: 'Supine Flexion' },
      { value: 'External Rotation', label: 'External Rotation' },
      { value: 'Internal Rotation', label: 'Internal Rotation' }
    ],
    selectedOption: 'Supine Flexion' | 'Select Option'
  },{
    name: 'Right Hip',
    key: 'rightHip',
    options: [
      { value: 'Supine Flexion', label: 'Supine Flexion' },
      { value: 'External Rotation', label: 'External Rotation' },
      { value: 'Internal Rotation', label: 'Internal Rotation' }
    ],
    selectedOption: 'Supine Flexion' | 'Select Option'
  },{
    name: 'Left Knee',
    key: 'leftKnee',
    options: [
      { value: 'Flexion', label: 'Flexion' },
      { value: 'Extension', label: 'Extension' }
    ],
    selectedOption: 'Extension' | 'Select Option'
  },
  {
    name: 'Right Knee',
    key: 'rightKnee',
    options: [
      { value: 'Flexion', label: 'Flexion' },
      { value: 'Extension', label: 'Extension' }
    ],
    selectedOption: 'Extension' | 'Select Option'
  },
  {
    name: 'Neck',
    key: 'neck',
    options: [
      { value: 'Flexion', label: 'Flexion' },
      { value: 'Extension', label: 'Extension' },
      { value: 'Left Lateral Flexion', label: 'Left Lateral Flexion' },
      { value: 'Right Lateral Flexion', label: 'Right Lateral Flexion' },
      { value: 'Left Rotation', label: 'Left Rotation' },
      { value: 'Right Rotation', label: 'Right Rotation' }
    ],
    selectedOption: 'Extension' | 'Select Option'
  },
  {
    name: 'Spine',
    key: 'spine',
    options: [
      { value: 'Flexion', label: 'Flexion' },
      { value: 'Extension', label: 'Extension' },
      { value: 'Left Lateral Flexion', label: 'Left Lateral Flexion' },
      { value: 'Right Lateral Flexion', label: 'Right Lateral Flexion' },
      { value: 'Left Rotation', label: 'Left Rotation' },
      { value: 'Right Rotation', label: 'Right Rotation' }
    ],
    selectedOption: 'Extension' | 'Select Option'
  },
]
