import {
	CreateProgramModalProps,
	IProgramData,
	IProgramExercise,
	IProgramTemplate,
} from '@stores/interfaces';
import { Collapse, Modal, Tabs } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { Check } from '@carespace-icons/general/check';
import { AddProgramPopup } from './AddProgramPopup';
import { TemplateModalData } from './TemplateModalData';
import { AiModalData } from './AiModalData';
import {
	getOpenAiProgram,
	getPreviousCarespaceAiProgram,
	getPreviousOpenAiProgram,
	resetCarespaceAiProgram,
	resetOpenAiProgram,
} from '@stores/aiAssistant/program';
import { Edit05 } from '@carespace-icons/general/edit05';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import loadingAi from './LoadingAi.json';
import { getSettingApiKey } from '@stores/settings/setings';
import strapi from 'src/Strapi';
import './style.css';
import { MyExrciseModalData } from './MyExerciseModalData';
import { Plus } from '@carespace-icons/general/plus';
import { getBodyRegions, getCategories, getJoints, getJointWithBodyRegion, setPopoverState } from '@stores/functionalGoals';
import { getSuggestedProgram } from '@stores/onBoard/onBoard';
import { USER_ROLES } from '@stores/constants';
import { useTheme } from '@pages/Themes/ThemeContext';

const { Panel } = Collapse;

export const CreateProgramModal = (props: CreateProgramModalProps) => {
	const {
		isVisible,
		onCancel,
		setRefresh,
		fetchData,
		refresh,
		searchValue,
		setSearchValue,
	} = props;

	const { t } = useTranslation();
	const { theme } = useTheme()
	const loadRef = useRef<LottieRefCurrentProps>(null);
	const [loadingMessage, setLoadingMessage] = useState('');
	const [activeKey, setActiveKey] = useState<string>('1');
	const [openFilter, setOpenFilter] = useState(false);
	const [selectedExercises, setSelectedExercises] = useState<
		IProgramExercise[]
	>([]);
	const [selectedProgram, setSelectedProgram] =
		useState<IProgramTemplate | null>();
	const [suggestedProgramData, setSuggestedProgramData] = useState([]);
	const [isModalVisible, setModalVisible] = useState(false);
	const dispatch = useTypedDispatch();
	const user = useTypedSelector(state => state.user);
	const [previousExercises, setPreviousExercises] = useState<
		IProgramExercise[]
	>([]);
	const { selectedUser } = useTypedSelector(state => state.contacts.main);
	const programTemplate = useTypedSelector(
		state => state.aiAssistant.program.programTemplate,
	);
	const { openAiProgram, carespaceAiProgram } = useTypedSelector(state => ({
		openAiProgram: state.aiAssistant.program.openAiProgram,
		carespaceAiProgram: state.aiAssistant.program.carespaceAiProgram,
	}));
	const openaiApiKey = useTypedSelector(
		state => state.settings.settings.openaiApiKey,
	);
	const [activeSubKey, setActiveSubKey] = useState('1');
	const [programId, setProgramId] = useState('null')
	const [strapiId, setStrapiId] = useState<number>(0);

	useEffect(() => {
		user?.profile?.role != USER_ROLES.USER && fetchingDataforFilters()
	}, [])

	const fetchingDataforFilters = async () => {
		await dispatch(getJoints());
		await dispatch(getCategories());
		await dispatch(getBodyRegions());
		await dispatch(getJointWithBodyRegion());
	};

	useEffect(() => {
		setOpenFilter(false);
	}, [isVisible, activeKey, onCancel]);

	useEffect(() => {
		dispatch(resetOpenAiProgram());
		user?.profile?.role != USER_ROLES.USER && dispatch(getSettingApiKey());
		getTemplate(1);
	}, [user, selectedUser]);

	useEffect(() => {
		activeKey == '3' && setActiveKey('1');
	}, [isVisible]);

	const loadMessages = () => {
		setLoadingMessage(
			t('Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.fetchData'
			));
		setTimeout(
			() => setLoadingMessage(t(
				'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.collectingResults',
			)),
			3500,
		);
		setTimeout(
			() => setLoadingMessage(t(
				'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.gatherData',
			)),
			6000,
		);
		setTimeout(
			() => setLoadingMessage(t(
				'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.reviewGoals',
			)),
			9000,
		);
		setTimeout(() => setLoadingMessage(t(
			'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.evaluatePain',
		)), 11500);
		setTimeout(
			() => setLoadingMessage(t(
				'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.determineExercises',
			)),
			13000,
		);
	};

	const getTemplate = async (current: number) => {
		try {
			const data = await dispatch(getSuggestedProgram(current))
			setSuggestedProgramData(data.payload);
		} catch {
			setSuggestedProgramData([]);
		}
	};

	const fetchPreviousOpenAiProgram = async () => {
		loadRef.current?.setSpeed(0.5);
		loadMessages();
		dispatch(getPreviousOpenAiProgram(selectedUser?.id));
	};

	const fetchPreviousCarespaceAiProgram = async () => {
		loadRef.current?.setSpeed(0.5);
		loadMessages();
		dispatch(resetCarespaceAiProgram());
		dispatch(getPreviousCarespaceAiProgram(selectedUser?.id));
	};

	const fetchOpenAiProgram = async () => {
		loadRef.current?.setSpeed(0.5);
		loadMessages();
		dispatch(resetOpenAiProgram());
		await dispatch(getOpenAiProgram(selectedUser?.id));
	};

	const fetchCarespaceAiProgram = async () => {
		loadRef.current?.setSpeed(0.5);
		loadMessages();
		dispatch(resetCarespaceAiProgram());
		await dispatch(getPreviousCarespaceAiProgram(selectedUser?.id));
	};

	const updateSelectedProgramExercises = (exerciseList: IProgramExercise[]) => {
		setSelectedProgram(prevState => {
			return prevState ? { ...prevState, exercises: exerciseList } : prevState;
		});
	};

	const extraContent = (data: IProgramData | IProgramTemplate) => {
		const handleSelectClick = () => {
			if (activeSubKey === '1') {
				setStrapiId(parseInt(data?.id));
			} else if (activeSubKey === '2') {
				setProgramId(data?.id);
			}
		};
		return (
			<div className="flex">
				<div className="">
					<p>
						<span className="font-semibold">
							{t(
								'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.duration',
							)}
						</span>{' '}
						{data?.duration} {data?.durationType}
					</p>
				</div>
				{activeKey === '2' && (
					<div
						className="-mr-4 align-middle"
						onClick={event => {
							event.stopPropagation();
						}}>
						<span
							onClick={() => {
								if (selectedProgram?.id === data.id) setSelectedProgram(null);
								else {
									if (!('clientId' in data)) {
										data = {
											...data,
											exercises: data?.exercises?.map(item => ({
												...item,
												strapiExerciseId:
													typeof item?.strapiExerciseId === 'number'
														? item?.strapiExerciseId
														: item?.strapiExerciseId?.id!,
												image:
													typeof item?.strapiExerciseId !== 'number'
														? item?.strapiExerciseId?.exercise_image[0]?.url!
														: '',
												name:
													typeof item?.strapiExerciseId !== 'number'
														? item?.strapiExerciseId?.name!
														: '',
											})),
										};
									}
									setSelectedProgram(data);
									setModalVisible(true);
								}
								handleSelectClick();
							}}
							className={`${
								selectedProgram?.id === data.id
									? 'bg-success-500 text-white'
									: 'bg-gray-100'
							} rounded-lg py-0.5 px-2 flex justify-center items-center gap-2`}>
							<Check
								onClick={() => handleSelectClick()}
								width={20}
								height={20}
								color={
									selectedProgram?.id === data.id
										? 'stroke-white'
										: 'stroke-black'
								}
							/>
							{selectedProgram?.id === data.id
								? t('Admin.data.menu.aiAssistant.aiAssistantPrograms.selected')
								: t('Admin.data.menu.aiAssistant.aiAssistantPrograms.select')}
						</span>
					</div>
				)}
			</div>
		);
	};

	const compare = (a: IProgramExercise, b: IProgramExercise) => {
		if (a.order < b.order) {
			return -1;
		}
		if (a.order > b.order) {
			return 1;
		}
		return 0;
	};

	const handleCancel = () => {
		dispatch(setPopoverState(false))
		onCancel();
		setSelectedExercises([]);
		setSelectedProgram(null);
		setModalVisible(false);
		setActiveKey('1');
		setActiveSubKey('1');
	};

	useEffect(() => {
		dispatch(setPopoverState(false))
	}, [activeKey])

	return (
		<Modal
			title={
				<div className="flex gap-4">
					<span>
						<Edit05 width={20} height={20} color="stroke-primary-600" />
					</span>
					<span className="titleStyle">
						{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.create')}
					</span>
				</div>
			}
			open={isVisible}
			onCancel={handleCancel}
			style={{ top: 20 }}
			footer={false}
			width={974}
			className="select-none createProgramModal">
			<Tabs
				defaultActiveKey={activeKey}
				activeKey={activeKey}
				className="createProgramTab select-none"
				tabBarStyle={{ margin: 0, backgroundColor: 'white' }}
				onChange={value => setActiveKey(value)}
				items={[
					{
						label: t(
							'Admin.data.menu.aiAssistant.aiAssistantPrograms.chooseLibrary',
						),
						key: '1',
						children: (
							<div className="rounded-none p-4 bg-gray-50">
								<MyExrciseModalData
									selectedExercises={selectedExercises}
									open={openFilter}
									setOpen={setOpenFilter}
									setSelectedExercises={setSelectedExercises}
								/>
								{selectedExercises.length !== 0 && (
									<div
										className="w-full cursor-pointer bg-success-500 rounded-lg flex justify-center items-center gap-2 text-white font-semibold p-2"
										onClick={() => setModalVisible(true)}>
										<Plus />
										<p>
											{t(
												'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.add',
											)}{' '}
											{selectedExercises.length}{' '}
											{selectedExercises.length === 1
												? t(
													'Admin.data.menu.aiAssistant.aiAssistantPrograms.exercise',
												)
												: t(
													'Admin.data.menu.aiAssistant.aiAssistantPrograms.exercises',
												)}
										</p>
									</div>
								)}
							</div>
						),
					},
					{
						label: t(
							'Admin.data.menu.aiAssistant.aiAssistantPrograms.selectTemplate',
						),
						key: '2',
						children: 
							<TemplateModalData
								fetchData={fetchData}
								searchValue={searchValue}
								setSearchValue={setSearchValue}
								program={programTemplate}
								open={openFilter}
								setOpen={setOpenFilter}
								extraContent={extraContent}
								activeSubKey={activeSubKey}
								setActiveSubKey={setActiveSubKey}
							/>
					},
					openaiApiKey && {
						label: (
							<p onClick={() => fetchPreviousOpenAiProgram()}>
								{t(
									'Admin.data.menu.aiAssistant.aiAssistantPrograms.generateAi',
								)}
							</p>
						),
						key: '3',
						children: openAiProgram.loading ? (
							<div className="bg-gray-50 !h-96 flex flex-col justify-center gap-2 items-center">
								<Lottie
									className="w-[300px]"
									lottieRef={loadRef}
									animationData={(loadingAi as Record<string, {}>)[theme]}
									loop={true}
									autoplay={true}
								/>
								<p className="text-lg font-semibold text-primary-600 absolute top-[60%] left-1/2 -translate-x-1/2">
									{loadingMessage}
								</p>
							</div>
						) : (
							<div className="p-4 bg-gray-100">
								<AiModalData
									program={openAiProgram}
									refresh={refresh}
									setRefresh={setRefresh}
									fetchOpenAiProgram={fetchOpenAiProgram}
									fetchData={fetchData}
									searchValue={searchValue}
									setSearchValue={setSearchValue}
									extraContent={extraContent}
								/>
							</div>
						),
					},
					{
						label: (
							<p onClick={() => fetchPreviousCarespaceAiProgram()}>
								{t(
									'Admin.data.menu.aiAssistant.aiAssistantPrograms.generateCarespace',
								)}
							</p>
						),
						key: '4',
						children: carespaceAiProgram.loading ? (
							<div className="bg-gray-50 !h-96 flex flex-col justify-center gap-2 items-center">
								<Lottie
									className="w-[300px]"
									lottieRef={loadRef}
									animationData={(loadingAi as Record<string, {}>)[theme]}
									loop={true}
									autoplay={true}
								/>
								<p className="text-lg font-semibold text-primary-600 absolute top-[60%] left-1/2 -translate-x-1/2">
									{loadingMessage}
								</p>
							</div>
						) : (
							<div className="p-4 bg-gray-100">
								<AiModalData
									program={carespaceAiProgram}
									refresh={refresh}
									setRefresh={setRefresh}
									fetchOpenAiProgram={fetchCarespaceAiProgram}
									fetchData={fetchData}
									searchValue={searchValue}
									setSearchValue={setSearchValue}
									extraContent={extraContent}
								/>
							</div>
						),
					},
				].filter(Boolean)}
			/>
			{isModalVisible && (
				<AddProgramPopup
					onOk={() => {
						setModalVisible(false);
						setSelectedExercises([]);
						handleCancel();
						fetchData(1);
						setRefresh(!refresh);
					}}
					refresh={refresh}
					setRefresh={setRefresh}
					programId={programId}
					strapiId={strapiId}
					isVisible={isModalVisible}
					activeKey={activeKey}
					thumbnailValue={activeSubKey ==='1' ? selectedProgram?.thumbnail?.url : selectedProgram?.thumbnail}
					onCancel={() => {
						setModalVisible(false);
						setSelectedProgram(null);
					}}
					selectedExercises={
						activeKey == '1' ? selectedExercises : selectedProgram?.exercises!
					}
					setSelectedExercises={
						activeKey == '1'
							? setSelectedExercises
							: updateSelectedProgramExercises
					}
					program={activeKey == '1' ? null : selectedProgram}
					isSaveTemplateVisible={
						selectedProgram?.exercises?.length === previousExercises?.length &&
						selectedProgram?.exercises?.every(e1 =>
							previousExercises.some(e2 => {
								return (
									e1.id === e2.id &&
									e1.reps === e2.reps &&
									e1.sets === e2.sets &&
									e1.dailyReps === e2.dailyReps &&
									e1.weeklyReps === e2.weeklyReps
								);
							}),
						)
					}
				/>
			)}
		</Modal>
	);
};
