import { Copy07 } from "@carespace-icons/general/copy07";
import { Menu01 } from "@carespace-icons/general/menu01";
import { setCollapsible } from "@stores/aiAssistant/aiAssistant";
import { USER_ROLES } from "@stores/constants";
import { useTypedDispatch, useTypedSelector } from "@stores/index";
import { Button, Input, message, QRCode, Radio } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function DashboardInviteCode() {
  const [inviteCode, setInviteCode] = useState("");
  const [selectedOption, setSelectedOption] = useState("text");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const { t } = useTranslation();
  const { user } = useTypedSelector((state) => ({
    user: state.user,
  }));

  useEffect(() => {
    if (user) {
      setInviteCode(user?.client?.inviteCode);
    }
  }, [user]);

  const isCollapsible = useTypedSelector(state => state.aiAssistant.aiAssistant.isCollapsible)
  const dispatch = useTypedDispatch()

  return (
		<>
			<div
				className="cursor-pointer p-2">
			</div>
			<div
				className={`flex flex-col md:flex-row items-center justify-center text-gray-800 gap-4 p-6 md:p-12
      `}>
				<div className="md:w-1/2 w-full space-y-6 text-center md:text-left">
					<h1 className="text-3xl">
						<span className="text-gray-600 font-inter text-[30px] leading-[45.99px] text-left">
							<span className="text-gray-700 font-bold">
								{t('Patient.data.dashboardInvite.careSpace')}
							</span>{' '}
							{t('Patient.data.dashboardInvite.improveMobility')}
						</span>
					</h1>

					<div className="bg-gray-100 p-3 rounded-2xl flex items-center justify-between">
						<span className="text-gray-700 font-inter text-[14px] font-semibold leading-[16.94px] text-left">
							{t('Patient.data.dashboardInvite.inviteCode')} :
						</span>
						<span className="text-gray-700 font-inter text-[20px] font-normal leading-[24.2px] text-left flex">
							{inviteCode}
							<span
								className="ml-2 cursor-pointer"
								onClick={() => {
									navigator.clipboard.writeText(inviteCode);
									message.success(t('Admin.data.menu.setting.openAi.copied'));
								}}>
								<Copy07 color="stroke-gray-700" />
							</span>
						</span>
					</div>

					<div className="space-y-2">
						<p className="text-gray-600 font-inter text-[20px] font-normal leading-[37.52px] text-left">
							{t('Patient.data.dashboardInvite.downloadApp')}
						</p>
						<div className="flex flex-wrap items-center gap-4">
							<QRCode
								value={'https://apps.apple.com/in/app/carespaceai/id6450214866'}
								size={150}
								style={{ backgroundColor: 'none' }}
							/>
							<img
								src="/images/dashboard/app-store.png"
								alt="Apple Store"
								className="h-16 w-48 inline cursor-pointer"
								onClick={() => {
									window.open(
										'https://apps.apple.com/in/app/carespaceai/id6450214866',
										'_blank',
										'noopener,noreferrer',
									);
								}}
							/>
						</div>
					</div>
				</div>

				<div className="md:w-1/2 w-full flex justify-center mt-8 md:mt-0">
					<img
						src="/images/dashboard/invite-dashboard.png"
						alt="CareSpace App Preview"
						className="max-w-md w-full"
					/>
				</div>
			</div>
		</>
	);
}
