import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import './style.less';
import { useTranslation } from 'react-i18next';
import { clearReports } from '@stores/report/reports';
import { setActiveTab } from '@stores/aiAssistant/aiAssistant';
import { useBlockNavigation } from '@atoms/ABlockNavigation';
import { Modal, Button } from 'antd';
import { router } from '@routers/routers';
import { ACTIVETAB, ROUTE_KEYS } from '@stores/constants';
import BreadCrumbTab from '@pages/BreadCrumbTab';

const navigationConfig = {
	activity: { path: router.ACTIVITY },
	myReport: { path: router.AIASSISTANT_MY_REPORT },
	listEvaluation: { path: router.AIASSISTANT_LIST_EVALUATION },
	romSummary: { path: router.AIASSISTANT_ROM_SUMMARY },
	captures: { path: router.AIASSISTANT_CAPTURES },
	startScan: { path: router.AIASSISTANT_START_SCAN },
	startRomScan: { path: router.AIASSISTANT_START_ROM_SCAN },
	postureScan: { path: router.AIASSISTANT_POSTURE_SCAN },
	postureSummary: { path: router.AIASSISTANT_POSTURE_SUMMARY },
	postureCaptures: { path: router.AIASSISTANT_POSTURE_CAPTURES },
	customSummary: { path: router.AIASSISTANT_CUSTOM_SUMMARY },
	customCaptures: { path: router.AIASSISTANT_CUSTOM_CAPTURES },
	generateProgram: { path: router.AIASSISTANT_GENERATE_PROGRAM, state: { isGenerate: true } },
	programs: { path: router.AIASSISTANT_PROGRAMS, state: { isGenerate: false } },
	listSessions: { path: router.AIASSISTANT_LIST_SESSIONS },
	downloadApp: { path: router.DOWNLOAD_APP },
	surveySummary: { path: router.AIASSISTANT_SURVEY_SUMMARY },
	virtualEvaluation: { path: router.AIASSISTANT_VIRTUAL_EVALUATION },
	createReport: { path: router.AIASSISTANT_CREATE_REPORT },
	assignSurvey: {
		path: router.AIASSISTANT_ASSIGN_SURVEY,
		state: { openSurveyModal: true, isGenerate: true },
	},
	createSurvey: {
		path: router.AIASSISTANT_CREATE_SURVEY,
		state: { openSurveyModal: false, isGenerate: false },
	},
	startSurveyUser: { path: router.AIASSISTANT_START_SURVEY_USER },
	newPatients: { path: router.NEW_PATIENTS },
	outOfParams: { path: router.OUTOFPARAMETERS },
	pendingReview: { path: router.PENDINGREVIEW },
	reviewed: { path: router.REVIEWED },
	escalationRequired: { path: router.ESCALATIONREQUIRED },
	noComponentFound: { path: router.AIASSISTANT_PAGENOTFOUND },
	notUnderstandQuestion: { path: router.AIASSISTANT_SEARCHNOTFOUND },
	overview: { path: "overview" }
};

export const AiAssistant = () => {
	const { t } = useTranslation();
	const existingPatientData = useTypedSelector(
		state => state.onBoard.onBoard.existingPatientData,
	);
	const location = useLocation();
	const dispatch = useTypedDispatch();
	const activeTab = useTypedSelector(
		state => state.aiAssistant.aiAssistant.activeTab,
	);
	const { user, selectedUser } = useTypedSelector(state => ({
		user: state.user,
		selectedUser: state.contacts.main.selectedUser,
	}));
	const { isBlocked } = useTypedSelector(
		state => state.aiAssistant.program.blockNavigation,
	);
	const { isCompleted } = useTypedSelector(
		state => state.aiAssistant.program.main,
	);

	const { handleNavigation, confirmNavigation, cancelNavigation } =
		useBlockNavigation(
			!isCompleted && location.pathname.includes(ROUTE_KEYS.PROGRAM_START),
			t(
				'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.unsaveChanges',
			),
		);

	useEffect(() => {
		const id = user?.isPhysioterapist ? selectedUser?.id : user?.id;
		const path = location.pathname.replace(`/${id}`, '');

		const newActiveTab = Object.keys(navigationConfig).find(
			key => navigationConfig[key].path === path,
		);
		if (newActiveTab) {
			dispatch(setActiveTab(newActiveTab));
		}
	}, [location.pathname, user, selectedUser]);

	useEffect(() => {
		dispatch(clearReports());
	}, [user, selectedUser]);

	useEffect(() => {
		const id = user?.isPhysioterapist ? selectedUser?.id : user?.id;
		const config = navigationConfig[activeTab];

		if (config) {
			const specialTabs = [
				'newPatients',
				'outOfParams',
				'pendingReview',
				'reviewed',
				'escalationRequired',
			];
			const fullPath = specialTabs.includes(activeTab)
				? `${config.path}`
				: `/${id}${config.path}`;

			if (location.pathname != fullPath)
				handleNavigation(fullPath, { state: config.state });
		}
	}, [activeTab, user, selectedUser]);


	return (
		<>
			<div
				className="fixed w-full z-[101] select-none mr-[0.90rem]"
				style={{ backgroundColor: '#fff' }}>
				{
					<>
						{(activeTab !== '' || location.pathname.includes(ROUTE_KEYS.PROGRAM_START)) &&<BreadCrumbTab />}
					</>
				}
			</div>
			<div
				className={`select-none ${activeTab != ACTIVETAB.DOWNLOAD_APP && activeTab != '' ? `${activeTab === ACTIVETAB.HOME ? (!existingPatientData || !user?.profile?.isWellnessCheck ? 'mt-[-84px]' : 'mt-[48px]') : `${location.pathname.includes('/download-app') ? 'mt-0' : 'mt-[4rem]'}`}` : 'mt-4'}`}>
				<Outlet />
			</div>
			{isBlocked && location.pathname.includes(ROUTE_KEYS.PROGRAM_START) && (
				<Modal
					title={t(
						'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.continueSession',
					)}
					open={isBlocked}
					onCancel={cancelNavigation}
					footer={null}
					centered>
					<p>
						{t(
							'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.unsaveChanges',
						)}
					</p>
					<div style={{ textAlign: 'right' }}>
						<Button
							type="default"
							onClick={cancelNavigation}
							style={{ marginRight: '10px' }}>
							{t('Patient.data.omnirom.no')}
						</Button>
						<Button type="primary" onClick={confirmNavigation}>
							{t('Patient.data.omnirom.yes')}
						</Button>
					</div>
				</Modal>
			)}
		</>
	);
};
